
.condensed {
  font-family: "Roboto Condensed" !important;
  line-height: 1 !important;
}

.no-wrap {
  white-space: nowrap;
}

.header-title {
  font-size: 32px;
  margin: 0 5% 0 1%;
  white-space: nowrap;
  color: #006798;
}

.header-description {
  font-family: "Roboto Condensed";
  font-size: 13px;
  max-width: 39%;
  margin: 0;
  color: #717171;
}

@media screen and (max-width: 600px) {
  .header-description {
    text-align: center;
  }
}
.css-k4hilk-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  border-bottom: solid 3px #006798;
}

.vline {
  border-left: 2px dotted var(--lightblue);
  align-self: stretch;
  opacity: 0.8;
}

/* .MuiToggleButton-root.Mui-selected {
  background-color: white !important;
  color: #006798 !important;
  font-weight: 600 !important;
} */

/* .MuiToggleButton-root {
  padding: 0 20px !important;
  height: 36px !important;
  background-color: rgba(255, 255, 255, 0.25) !important;
  border-radius: 26px !important;
} */

/* .MuiToggleButton-root:not(.selected):hover {
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.4));
}
 */
/* .MuiToggleButtonGroup-grouped:first-of-type {
  margin-left: -30px !important;
} */

/* .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  margin-left: -30px !important;
} */
